/*body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}*/

#logo{
  width: 150px;
  /*height: 150px;*/
}

.logo{
  width: 250px;
  /*height: 150px;*/
}

label{
  color: #3b4cb8;
  font-weight: bold;
}

button{
  float: right;
}

.form-control{
  font-weight: bold;
  color: #000;
}
table, tr, td{
  text-align: center;
}
table, td{
  color: #000;
  font-weight: bold;
}
/*.modal-backdrop{
  display: none;
}*/