@media print {
    @page {
        /* margin-top: 0.75in;
        margin-bottom: 0.75in;
        margin-left: 0.75in;
        margin-right: 0.75in;     */
        background-color: black;
        padding: 0;
        width: 21cm;
        height: 29.7cm;
        margin: 0;
        min-width: 100%;
    }

    a[href]:after {
        content: none !important;
        display: none;
    }
    input{
      width: 90px;
      font-size: 14px;  
    }
    #btnPrint{
        display: none;
    }

    .logoAl{
        margin-top: -20%;
    }

    #logoEtat{
         margin-top: -50% !important;
    }

    #faitAEtat{
        margin-top: -10% !important;
    }

    #cardEtat{
         margin-top: -8% !important;
    }



    #btnRetour{
        display: none;
    }

    .header {
        display: none;
    }

    .footer {
        display: none;
    }

    .deznav {
        display: none;
    }

    .nav-header {
        display: none;
    }

    /* .navbar {
        display: none;
        background: white !important;
    } */

    .dropdown {
        display: none;
    }

    .bouton {
        display: none;
    }

    #proforma{
        display: none;
    }

    .content-body {
        padding: 0;
        margin: 0;
    }

    .footerText {
        /*margin-top: 300px !important;*/
        text-align: center !important;
         position: fixed !important;
        font-size: 9px !important;
    }

    .footTxtTwo {
        font-size: 12px !important;
    }

    .txtFF {
        margin-top: -100px !important;
    }

    .footTxtFor {
        margin-top: 100px !important;
    }

    h6 {
        font-size: 12px !important;
    }

    #tableau{
        margin-top: 30%;
    }

    #nbrlotvendu{
        position: absolute !important;
        margin-top: -25px !important;
        width: 600px;

    }

    .thClient{
        border: none !important;
    }

    #lineLot{
        border-bottom: none !important;
    }

    #totalColspan{
        border-top: none !important;
        border-bottom: none !important;
        border-left: none !important;

    }

    

    #divPrint{
        margin-top: 23% !important;
    }

    #InfoVente{
        font-size: 12px !important;
    }


    .divFact{
        margin-top: -5% !important;
    }

    #InfoFacture{
        margin-top: -8% !important;
    }

    #signatureP{
         margin-top: -3% !important;
    }

    
    #TextFoot{
         margin-top: 20% !important;
    }
}