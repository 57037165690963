@media print {
    @page {
           
        background-color: black;
        padding: 0;
       /* width: 21cm;
        height: 29.7cm;*/
        margin: 0;
        /*min-width: 100%;*/
        size: A4 portrait;
    }

    a[href]:after {
        content: none !important;
        display: none;
    }

     #btnPrint{
        display: none;
    }

    #btnRetour{
        display: none;
    }

    .header {
        display: none;
    }

    .footer {
        display: none;
    }

    .deznav {
        display: none;
    }

    .nav-header {
        display: none;
    }

    /* .navbar {
        display: none;
        background: white !important;
    } */

    .dropdown {
        display: none;
    }

    .bouton {
        display: none;
    }

    #client{
        display: none;
    }


        #fiche1{
            margin-top: -5% !important;
            margin-left: -3% !important;
           /* width: 21cm !important;
            height: 29.7cm !important;*/

        }

        #titreS1{
            border: 2px solid #000 !important;
        }



        #titreS2{
            border: 2px solid #000 !important;
        }

        td, th{
            border-bottom: 1px solid #000 !important;
            border-right: 1px solid #000 !important;
            border-left: 1px solid #000 !important;
        }

        #td_sis, #td_prix{
            border-right: none !important;
            border-left: none !important;
        }

        #td_adresse{
             border-right: none !important;
        }

        #td_ville{
             border-left: none !important;
        }


        #dlogo{
            position: absolute;
             margin-top: -15% !important;
              margin-left: 4% !important;
        }

         #logo{
            display: initial !important;
            margin-top: -37% !important;
           
         }

         #Rtitle{
            text-align: center;
            width: 650px !important;
            font-size: 18px !important;
            
         }

         #dtitle{
             margin-top: -15% !important;
              margin-left: 8% !important;
         }

         #textfoot{
            font-size: 16px;
            color: #000;
            font-weight: bold;
         }

         #d250{
            position: absolute;
         }

         #d300{
             position: absolute;
              margin-left: 16% !important;
         }

         #d500{
             position: absolute;
              margin-left: 32% !important;
         }

         #dm2{
             position: absolute;
              margin-left: 48% !important;
              
         }

         #titre_mod{
              margin-left: -18% !important;
             position: absolute;
         }

         #titre_mod_paie{
            color: #000 !important;
            font-size: 12px !important;
            margin-top: 1% !important;
         }

        
        #mode{
            float: right;
             margin-left: 82% !important;
         }



        #cash{
           color: #000 !important; 
            font-size: 12px !important;
            font-weight: bold;
        }
        #virement{
           color: #000 !important;
            font-size: 12px !important;
            font-weight: bold; 
        }
        #cheque{
           color: #000 !important; 
            font-size: 12px !important;
            font-weight: bold;
        }


         #inputM2{
            width: 50px !important;
         }




          #label250{
              font-size: 24px !important;
         }

         #checkbox250{
            width: 32px !important;
            height: 20px !important;
            margin-left: -1% !important;
         }

          #label300{
            margin-left: -1% !important;
              font-size: 24px !important;
         }

         #checkbox300{
            width: 32px !important;
            height: 20px !important;
         }

          #label500{
             margin-left: -1% !important;
              font-size: 24px !important;
         }

         #checkbox500{
            width: 32px !important;
            height: 20px !important;
         }

         #input1{
            width: 280px !important;
             /*border: 1px solid #000 !important;*/

         }

         #input1_2{
            width: 280px !important;
             /*border: 1px solid #000 !important;*/

         }


         #input3{
             position: absolute;
             width: 110px !important;
             font-size: 12px !important;
            margin-top: 4px !important;
            margin-left: 2px !important;

             /*border: 1px solid #000 !important;*/
         }
         
         #input3_1{
             /*border: 1px solid #000 !important;*/
         }


         #input3_2{
            position: absolute;
             width: 110px !important;
             font-size: 12px !important;
             margin-top: 4px !important;
            margin-left: 2px !important;
         }

         #co_ville{
            margin-left: 38% !important;
         }

         #sc_ville{
             margin-left: 35% !important;
         }

         #input3_2_3{
           /*width: 80px !important;*/
            /*border: 1px solid #000 !important;*/
             font-size: 12px !important;
              margin-left: 2px !important;
         }

         #input2{
            /*border: 1px solid #000 !important;*/
            width: 220px !important;
         }

         #input2_2{
            /*border: 1px solid #000 !important;*/
            width: 220px !important;
         }


         #input8{
            /*border: 1px solid #000 !important;*/
            height: 19px !important;

         }

         #input9{
            /*border: 1px solid #000 !important;*/
            height: 19px !important;

         }

         #input10{
            /*border: 1px solid #000 !important;*/
            height: 20px !important;

         }

         #input11{
            /*border: 1px solid #000 !important;*/
            height: 20px !important;

         }


         #input13{
            /*border: 1px solid #000 !important;*/
            height: 19px !important;

         }


         #dsis{
            margin-top: -3% !important;
         }

         #dcondition{
            margin-top: 25% !important;

         }

         #vide{
            position: absolute;

         }

         #faita{
            position: absolute;
             width: 200px !important;
              margin-left: 40% !important;
         }

         #fait_a{
            width: 170px !important;
            margin-left: 15% !important;
              margin-top: 2px !important;
              border-bottom: 1px solid #000 !important;
              border-top:none  !important;
              border-left:none  !important;
              border-right:none  !important;
         }

         #date_fait{
            margin-left: 75% !important;
            width: 255px !important;

         }

         #condition_g{
             margin-top: 15% !important;
         }


         #Signature1{
            position: absolute;
         }

          #Signature2{
            
             margin-left: 40% !important;
         }

          #Signature3{
             margin-top: -8% !important;
             margin-left: 70% !important;
         }

         #titre_sign1{
            font-size: 14px !important;
            color: #000 !important;
         }

         #titre_sign2{
            font-size: 14px !important;
            color: #000 !important;
         }

         #titre_sign3{
            font-size: 14px !important;
            color: #000 !important;
         }


         #footerBottom{
             margin-top: 25% !important;
         }

         #dvilla{
            position: absolute;
         }

         #dDupl{
             margin-left: 25% !important;
             position: absolute;
         }

         #dAppart{
             margin-left: 45% !important;
             position: absolute;
         }

         #Dtitre_mod_reserv{
              margin-left: 75% !important;
         }

         #titre_mod_reserv{
             color: #000 !important;
             font-size: 14px !important;
             font-weight: bold !important;
         }




    }