@media print {
    @page {
        /* margin-top: 0.75in;
        margin-bottom: 0.75in;
        margin-left: 0.75in;
        margin-right: 0.75in;     */
        background-color: black;
        padding: 0;
        /*width: 21cm;*/
        /*height: 29.7cm;*/
        margin: 0;
        min-width: 100%;
        size: A4;
         /*size: A4  landscape;*/
    }

    a[href]:after {
        content: none !important;
        display: none;
    }

     #btnPrint{
        display: none;
    }

    #btnRetour{
        display: none;
    }

    .header {
        display: none;
    }

    .footer {
        display: none;
    }

    .deznav {
        display: none;
    }

    .nav-header {
        display: none;
    }

    /* .navbar {
        display: none;
        background: white !important;
    } */

    .dropdown {
        display: none;
    }

    .bouton {
        display: none;
    }

    #client{
        display: none;
    }

    .card{
        display: none;
    }



    #fiche3{
            margin-top: 2%;
            margin-left: -260px;
            width: 1070px !important;
            height: 768px !important;
        }

     #table{
        /*padding: 2%;*/
         margin-top: -5%;
         width: 70% !important;
          margin-left: 23% !important;

     }

     th{
        font-size: 12px !important;
     }

     #entete1{
        /*position: absolute !important;*/
         /*margin-left: 20% !important;*/
         margin-top: -5% !important;
         font-size: 14px !important;
          width: 115% !important;
          /*border: 1px solid #000;*/
     }

     #typ_bien{
         margin-left: 26% !important;
     }

     

     #Einput1{
        margin-left: -7px !important;
        border: none;
        text-align: center;
        /*border: 1px solid #000;*/
     }

     #Einput2{
        margin-left: -5px !important;
        border: none;
        width: 90px !important;
        /*border: 1px solid #000;*/
     }

     #Einput3{
        margin-left: -7px !important;
        border: none;
        width: 40px !important;
        /*border: 1px solid #000;*/
     }

     #Einput4{
        margin-left: -3px !important;
        border: none;
        width: 40px !important;
        /*border: 1px solid #000;*/
     }

     th{
        font-size: 13px;
        font-weight: bold;
        text-align: center;
     }

     td{
        font-size: 14px;
        text-align: center;
     }

     #fob{
        width: 80px !important;
     }

     #mois{
        width: 80px !important;
     }

     #num_cli{
        width: 50px !important;
     }

     
    

     #logo{
        display: initial !important;
        margin-top: -25% !important;
        margin-left: -30% !important;
     }

     #title{
         margin-top: -10% !important;
         margin-left: 18% !important;
         width: 500px !important;
     }

     #title_titre{
         margin-top: 4% !important;
     }

    #codeClit{
        position: absolute !important;   
        margin-left: 5% !important;
         margin-top: 10% !important;
    }

    #nom_client{
        font-size: 18px !important;
    }

    #dateEng{
        margin-left: -95% !important; 

    }



    #signature{
        margin-top: -48% !important;
    }

     #suivi{
        position: absolute !important;  
         margin-left: -2% !important;
       
    }

    #suivi1{
          font-size: 18px !important;
         color: #000 !important;
    }

    #suivi2{
         font-size: 12px !important;
         color: #000 !important;
    }

    #suivi3{
        font-weight: bold;
         font-size: 14px !important;
         color: #000 !important;
    }

    #directeur{
        position: absolute !important;  
         margin-left: 30% !important;
         font-size: 18px !important;
         color: #000 !important;
    }

    #clientSIgn{
         margin-left: 67% !important;
         font-size: 12px !important;
         color: #000 !important;
    }

     #clientSIgn1{
         font-size: 18px !important;
         color: #000 !important;
    }

    #clientSIgn2{
         font-size: 12px !important;
         color: #000 !important;
    }

    #Einput5{
        width: 150px !important;
        text-transform: uppercase !important;
        font-size: 12.5px !important;
        margin-left: -4px !important;
        text-align: left !important;
        /*border-right: 1px solid #000 !important;*/
    }

    #Einput6{
       width: 75px !important;
        text-transform: uppercase !important;
        font-size: 12px !important;
        /*margin-left: -4% !important;*/
        /*border: 1px solid #000 !important;*/
    }

    #Einput7{
         width: 70px !important;
        text-transform: uppercase !important;
         /*margin-left: -5% !important;*/
          font-size: 12px !important;
        /*border: 1px solid #000 !important;*/

    }

    #Einput8{
        width: 75px !important;
        /*margin-left: -3% !important;*/
         font-size: 12px !important;
        /*border: 1px solid #000 !important;*/
    }

    #Einput9{
        width: 75px !important;
        /*margin-left: -3% !important;*/
        /*border: 1px solid #000 !important;*/
        font-size: 12px !important;
    }

    #Einput10{
        width: 75px !important;
        /*margin-left: -5% !important;*/
        /*border: 1px solid #000 !important;*/
        font-size: 12px !important;

    }

    #Einput11{
        width: 65px !important;
        /*margin-left: -4% !important;*/
        /*border: 1px solid #000 !important;*/
        font-size: 12px !important;
    }

    #Einput12{
        width: 75px !important;
        /*margin-left: -4px !important;*/
        /*border: 1px solid #000 !important;*/
        font-size: 12px !important;

    }

    #Einput13{
         width: 60px !important;
        /*margin-left: -4px !important;*/
        /*border: 1px solid #000 !important;*/
        font-size: 12px !important;
    }

    #Einput14{
         width: 60px !important;
        /*margin-left: -5px !important;*/
        /*border: 1px solid #000 !important;*/
        font-size: 12px !important;
    }

    #Einput15{
         width: 60px !important;
        /*margin-left: -4px !important;*/
        /*border: 1px solid #000 !important;*/
        font-size: 12px !important;

    }

     #Einput16{
        /*border: 1px solid #000 !important;*/
        width: 70px !important;
         font-size: 12px !important;
    }

    #Einput19{
         width: 75px !important;
        margin-left: -3px !important;
        font-size: 11px !important;
        /*border: 1px solid #000 !important;*/
    }


    #Einput18{
         width: 60px !important;
         /*margin-left: -5% !important;*/
        /*border: 1px solid #000 !important;*/

    }

}